import { useLocation } from 'react-router-dom';

import './App.css';
import { KJUR } from 'jsrsasign'
import { ZoomMtg } from '@zoom/meetingsdk';
import Swal from 'sweetalert2';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

function getRandomArbitrary(min, max) {
  return parseInt(Math.random() * (max - min) + min);
}

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function App() {

  var randomCheckConnection = 0;
  var minCheckConnection = 1800000; // 30 minuti
  var maxCheckConnection = 3600000; // 60 minuti
  var timeCheckConnection = 60000; // 60 secondi
  //var minCheckConnection = 60000; // 60 secondi
  //var maxCheckConnection = 120000; // 120 secondi
  //var timeCheckConnection = 60000; // 60 secondi
  var max_id_message = 0;
  var session_id = uuidv4();
  // var messaggioutenteattivo = false;
  const caller = 'https://learning.connextinglearn.it/';
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  var sdkKey = queryParams.get('k');
  var sdkSecret = queryParams.get('s');
  var meetingNumber = queryParams.get('m');
  var passWord = queryParams.get('p');
  var role = queryParams.get('u');
  var leaveUrl = caller+queryParams.get('leave');
  var userName = queryParams.get('userName');
  var userEmail = queryParams.get('userEmail');
  var dirette_id = queryParams.get('dirette_id');
  var studenti_id = queryParams.get('studenti_id');
  var docenti_id = queryParams.get('docenti_id');
  var argomenti_id = queryParams.get('argomenti_id');
  var users_id = queryParams.get('users_id');
  var controllipresenza = queryParams.get('cp');

  //var sdkKey = '1hSmMAeHQrCOnjngj5nM6g'
  //var sdkSecret = 'kEimE3L4BT2l5dwNdNzu1cedHfynzKdy'
  //var meetingNumber = '88679052507'
  //var passWord = 'nF6bPT0h1L8NEGhSytgIujDXaOrbZ2.1'
  //var role = '0'
  //var leaveUrl = 'https://meeting.connextinglearn.it?r='+getRandomArbitrary(10000,99999)
  //var userName = 'User '+getRandomArbitrary(10000,99999)
  //var userEmail = 'test@connexting.it'

  if(!sdkKey || !sdkSecret || !meetingNumber || !passWord || !role || !leaveUrl || !userName || !userEmail) {
    alert('Parametri necessari per inizializzazione non validi');
    return;
  }

  if(role!='0' && role!='1') {
    alert('Il ruolo deve essere valorizzato correttamente (0,1)');
    return;
  }

  if(role=='0' && (!dirette_id || !studenti_id || !argomenti_id || !users_id)) {
    alert('Il tuo ruolo richiede parametri aggiuntivi');
    return;
  }
  
  var registrantToken = ''
  var zakToken = ''

  if (window.crossOriginIsolated) {
    //console.log('SharedArrayBuffer è supportato in Firefox!');
  } else {
    //console.log('SharedArrayBuffer non è supportato in Firefox.');
  }

  function getSignature() {
    
    const expirationSeconds = 0
    const iat = Math.floor(Date.now() / 1000)
    const exp = expirationSeconds ? iat + expirationSeconds : iat + 60 * 60 * 2
    const oHeader = { alg: 'HS256', typ: 'JWT' }

    const oPayload = {
      appKey: sdkKey,
      sdkKey: sdkKey,
      mn: meetingNumber,
      role,
      iat,
      exp,
      tokenExp: exp
    }

    const sHeader = JSON.stringify(oHeader)
    const sPayload = JSON.stringify(oPayload)
    const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret)
    startMeeting(sdkJWT)
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      patchJsMedia: true,
      screenShare: (role=='1'), // condivisione schermo: true se docente, false altrimenti
      disableInvite: (role=='0'), // inviti disabilitati: true se studente, false altrimenti
      disableRecord: (role=='0'), // Disabilita il pulsante di registrazione: true se studente, false altrimenti
      isSupportChat: true,
      isSupportQA: false, // Disabilita Q&A
      isSupportPolling: false, // Disabilita i sondaggi
      isSupportBreakout: false, // Disabilita le Breakout Rooms
      leaveOnPageUnload: true,
      meetingInfo: [
        'topic',
        'host',
        'participant',
        'id'
      ], // Specifica quali informazioni mostrare nella UI
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success)
            refreshMessaggi();

            if(role=='0') {
              if(controllipresenza=='S') {
                randomCheckConnection = getRandomArbitrary(minCheckConnection,maxCheckConnection);
                var randomSecondi = parseInt(randomCheckConnection/1000);
                randomCheckConnection = randomSecondi * 1000;
                console.log('check connection tra millisecondi: '+randomCheckConnection);
                setTimeout(checkConnection, randomCheckConnection);
              }
            }
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  var countDownTimeout;

  function checkConnection() {

    countDownTimeout = setInterval(function() {
        refreshStatus('PRESENCE_NOT_OK');
    }, timeCheckConnection);
    
    Swal.fire({
        icon: 'warning',
        title: 'Conferma presenza',
        text: 'Sei ancora davanti allo schermo? Clicca OK per evitare che la diretta si chiuda automaticamente.',
        showConfirmButton: true,
        confirmButtonText: 'OK',
        allowOutsideClick: false
    }).then((result) => {
        if (result.isConfirmed) {
            clearInterval(countDownTimeout);
            refreshStatus('PRESENCE_OK');
        }
    });
  }

  function refreshStatus(status) {

    fetch(`${caller}public/index/refreshstatusdirettaexternal?r=${Math.random()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dirette_id: dirette_id,
        studenti_id: studenti_id,
        argomenti_id: argomenti_id,
        status: status,
        session_id: session_id,
        users_id: users_id,
      })
    })
    .then(response => response.json())
    .then(data => {
    })
    .catch(error => {
    })
    .finally(() => {
      refreshStatusFinish(status);
    })
  }

  function refreshStatusFinish(status) {
    if(status=='PRESENCE_NOT_OK') {
        window.location.href = leaveUrl;
    } else if(status=='PRESENCE_OK') {
        randomCheckConnection = getRandomArbitrary(minCheckConnection,maxCheckConnection);
        var randomSecondi = parseInt(randomCheckConnection/1000);
        randomCheckConnection = randomSecondi * 1000;
        console.log('check connection tra millisecondi: '+randomCheckConnection);
        setTimeout(checkConnection, randomCheckConnection);
    }
  }

  function refreshMessaggi () {
    
    if(role=='1') {

      fetch(`${caller}public/index/refreshmessaggiexternaldocenti?r=${Math.random()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          max_id_message: max_id_message,
          dirette_id: dirette_id,
          docenti_id: docenti_id,
          argomenti_id: argomenti_id,
          users_id: users_id,
          session_id: session_id,
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'CLOSED') {
          Swal.fire({
            icon: 'warning',
            title: 'Disconnessione in corso',
            text: 'Attenzione! Hai effettuato l\'accesso un altro browser e verrai disconnesso.',
            showConfirmButton: false,
            timer: 5000
          });
  
          setTimeout(() => {
            window.location.href = `${caller}docente/main`;
          }, 5000);
  
          return;
        }
  
        session_id = data.session_id;
      })
      .catch(error => {
        console.error('Errore:', error);
      })
      .finally(() => {
        setTimeout(refreshMessaggi, 5000); // Richiama `refreshMessaggi` dopo 5 secondi
      });

    } else {

      fetch(`${caller}public/index/refreshmessaggiexternal?r=${Math.random()}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          max_id_message: max_id_message,
          dirette_id: dirette_id,
          studenti_id: studenti_id,
          argomenti_id: argomenti_id,
          users_id: users_id,
          session_id: session_id,
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'CLOSED') {
          Swal.fire({
            icon: 'warning',
            title: 'Disconnessione in corso',
            text: 'Attenzione! Hai effettuato l\'accesso un altro browser e verrai disconnesso.',
            showConfirmButton: false,
            timer: 5000
          });
  
          setTimeout(() => {
            window.location.href = `${caller}studente/main`;
          }, 5000);
  
          return;
        }
  
        /*
        if (data.messagetouser) {
          if(!messaggioutenteattivo) {
            Swal.fire({
              icon: 'info',
              title: 'Messaggio dal docente',
              text: data.messagetouser,
              showConfirmButton: false
            });
            messaggioutenteattivo = true;
          }
        } else {
          Swal.close();
          messaggioutenteattivo = false;
        }
        */
  
        session_id = data.session_id;
      })
      .catch(error => {
        console.error('Errore:', error);
      })
      .finally(() => {
        setTimeout(refreshMessaggi, 5000); // Richiama `refreshMessaggi` dopo 5 secondi
      });
    }
  }

  getSignature();

  return (
    {/*
    <div className="App">
      <main>
        <h1>Connexting Learn Zoom Meeting</h1>

        <button onClick={getSignature}>Accedi</button>
      </main>
    </div>
    */}
  );
}

export default App;
